import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { servicesLinks } from 'components/Layout/navigation';
import Service from 'components/Service_Shipping';
import { ImgGatsbyBackground } from 'components/wrappers';
import Icon1 from 'images/icon1_ship2.png';
import Icon2 from 'images/icon2_ship2.png';
import Icon3 from 'images/icon3_ship2.png';
import Step1 from 'images/steps/express-shipping/merchants.png';
import Step2 from 'images/steps/express-shipping/package-pickup.png';
import Step3 from 'images/steps/express-shipping/registration.png';
import Step4 from 'images/steps/express-shipping/transport.png';
import Step5 from 'images/steps/express-shipping/tracking.png';
import Step6 from 'images/steps/express-shipping/pickup.png';

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ImgShipmentQuery {
      file(relativePath: { eq: "fba-prep-and-forwarding-hero-bg3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo ecommerce international express shipping solutions" />;
  const IconCardOne = () => <img src={Icon1} alt="Icon" />;
  const IconCardTwo = () => <img src={Icon2} alt="Icon" />;
  const IconCardThree = () => <img src={Icon3} alt="Icon" />;
  const intl = useIntl()
  return (
    <Service
      BackgroundImage={BackgroundImage}
      IconCardOne={IconCardOne}
      IconCardTwo={IconCardTwo}
      IconCardThree={IconCardThree}
      navigationTabs={servicesLinks}
      activeTab={servicesLinks[1].value}
      // description={
      //   <>
      //     {intl.formatMessage({ id: "express-shipping-header-description-one" })}
      //     <br />
      //     <br />
      //     {intl.formatMessage({ id: "express-shipping-header-description-two" })}
      //   </>
      // }
      header={
        <>
          {intl.formatMessage({ id: "china-air-freight-sub-header-description" })}
        </>
      }
      about={intl.formatMessage({ id: "china-air-freight-header" })}
      cardsTitle={[
        "Global Network Coverage",
        'Cost Effective Service',
        'Free Saas Solution',
      ]}
      cards={[
        'Global network coverage—from anywhere to anywhere by using our routing algorithms to find the best shipping network',
        '3-5 days end-to-end full track-and-trace delivery at 20% of the costs of a courier service',
        'Our SaaS system minimizes your workload by integrating orders from multiple channels, printing shipping labels, and taking care of dispatching',
      ]}
      numSteps={intl.formatMessage({ id: "express-shipping-process-how-it-works-explained" })}
      timelineItems={[
        {
          titleStep: 'Step 1',
          title: 'Order Integration',
          description:'Sync or import your orders into Shipkoo’s platform, then print the labels for your packages',
          image: Step1,
          alt: 'shipkoo best express shipping solution for ecommerce product sync to shipkoo platform',
          titleStepZH: '步骤1',
          titleZH: '同步',
          descriptionZH: '将订单同步或导入到Shipkoo酷运的平台中，然后打印包裹标签',
          titleStepKO: '1단계',
          titleKO: '주문 통합',
          descriptionKO: '주문을 Shipkoo의 플랫폼으로 동기화 또는 가져온 다음 패키지의 레이블 인쇄',
          titleStepJP: 'ステップ1',
          titleJP: '注文の統合',
          descriptionJP: '注文をShipkooのプラットフォームに同期またはインポートしてから、パッケージのラベルを印刷します',
        },
        {
          titleStep: 'Step 2',
          title: 'Package Pickup',
          description: 'Arrange local pickup or drop off your packages at Shipkoo’s nearest hub',
          image: Step2,
          alt: 'shipkoo best express shipping solution for ecommerce package pickup',
          titleStepZH: '步骤2',
          titleZH: '安排送货',
          descriptionZH:'安排当地物流运输或送货至Shipkoo酷运最近的仓配中心',
          titleStepKO: '2단계',
          titleKO: '패키지 픽업',
          descriptionKO: 'Shipkoo의 가장 가까운 허브에서 로컬 픽업 또는 패키지 하차 준비',
          titleStepJP: 'ステップ2',
          titleJP: 'パッケージピックアップ',
          descriptionJP: 'ローカルピックアップを手配するか、Shipkooの最寄りのハブで荷物を降ろします',
        },
        {
          titleStep: 'Step 3',
          title: 'Registration',
          description: 'Shipkoo registers your package and processes its weight and dimensions',
          image: Step3,
          alt: 'shipkoo best express shipping solution for ecommerce package registration',
          titleStepZH: '第三步',
          titleZH: '登记',
          descriptionZH:'Shipkoo酷运 识别您的包裹并其重量和尺寸',
          titleStepKO: '3단계',
          titleKO: '등록',
          descriptionKO: 'Shipkoo는 당신의 패키지를 등록하고 그것의 무게와 치수를 처리한다.',
          titleStepJP: 'ステップ3',
          titleJP: '登録',
          descriptionJP: 'Shipkooはパッケージを登録し、その重量と寸法を処理します',
        },
        {
          titleStep: 'Step 4',
          title: 'Shipping',
          description: 'Shipkoo palletizes your package, handles custom clearance, and air freights it to the destination country, where our Express Shipping Company hand it off to a local partner for last-mile delivery',
          image: Step4,
          alt: 'shipkoo express shipping solutions for ecommerce product transport',
          titleStepZH: '第四步',
          titleZH: '运输',
          descriptionZH: 'Shipkoo 将您的包裹装在托盘上，处理清关，并将其空运到目的地国家，我们的快递运输公司将其交给当地合作伙伴进行最后一英里交付',
          titleStepKO: '4단계',
          titleKO: '배송',
          descriptionKO: 'Shipkoo는 당신의 소포를 팔레트에 담아 세관 통관을 처리하고 항공 화물을 목적지 국가로 운송하며, 우리의 Express Shipping Company는 마지막 마일 배송을 위해 지역 파트너에게 전달합니다',
          titleStepJP: 'ステップ4',
          titleJP: '発送',
          descriptionJP: 'Shipkoo は荷物をパレットに積み、通関手続きを処理し、目的地の国に空輸し、そこでエクスプレス配送会社がラストマイル配送のために地元のパートナーに引き渡します。',
        },
        {
          titleStep: 'Step 5',
          title: 'Tracking',
          description:'Shipkoo automatically generates tracking numbers and makes them available to your customers, who have full visibility on when their package will arrive',
          image: Step5,
          alt: 'shipkoo express shipping solutions for ecommerce product tracking',
          titleStepZH: '第五步',
          titleZH: '追踪',
          descriptionZH:'Shipkoo酷运 自动生成跟踪号并将其发送给客户，客户可以随时查看到他们的包裹何时到达',
          titleStepKO: '5단계',
          titleKO: '추적',
          descriptionKO: 'Shipkoo는 자동으로 추적 번호를 생성하여 언제 패키지가 도착할지 완벽하게 파악할 수 있는 고객에게 제공',
          titleStepJP: 'ステップ5',
          titleJP: '追跡',
          descriptionJP: 'Shipkooは自動的に追跡番号を生成し、あなたの顧客がいつ荷物が届くかを完全に把握できるようにします。',
        },
        {
          titleStep: 'Step 6',
          title: 'Delivery',
          description: 'The product is delivered to the end-customer',
          image: Step6,
          alt: 'shipkoo express shipping solutions for ecommerce product pickup',
          titleStepZH: '第六步',
          titleZH: '收货',
          descriptionZH:'客户收到包裹',
          titleStepKO: '6단계',
          titleKO: '배달',
          descriptionKO: '제품이 최종 고객에게 배송됨',
          titleStepJP: 'ステップ6',
          titleJP: '配送',
          descriptionJP: '顧客はパッケージを受け取ります',
        },
      ]}
    />
  );
};

export default ServicesPage;
